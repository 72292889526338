import React from 'react'
import Img from 'gatsby-image'

interface IProps {
  children?: React.ReactNode
  imgFluid?: any
  imgFixed?: any
  className?: string
}

const BgImage: React.FC<IProps> = ({
  children,
  imgFixed,
  imgFluid,
  className,
}) => (
  <div
    className={`position-relative bg-image ${className}`}
    style={{ overflow: 'hidden' }}
  >
    {imgFixed && <Img fixed={imgFixed} alt="background" />}
    {imgFluid && <Img fluid={imgFluid} alt="background" />}
    <div className="position-relative">{children}</div>
  </div>
)

export default BgImage
