import React, { useState } from 'react'
import { FormGroup, FormText, Input, Label, InputProps } from 'reactstrap'

interface IProps {
  label?: string
  require?: boolean
  type?: InputProps['type']
  name?: string
  id?: string
  formText?: string
  placeholder?: string
  ref?: any
  value?: string
}

const CustomFormGroup: React.FC<IProps> = ({
  require,
  label,
  type,
  name,
  id,
  formText,
  placeholder,
  ref,
  value,
}) => {
  const [focused, setFocused] = useState<boolean>(false)

  const handleFocus = () => !focused && setFocused(true)

  const handleBlur = () => !focused && setFocused(false)

  return (
    <FormGroup className={`form-group-default ${focused && 'focused'}`}>
      {label ? (
        <Label>
          {label}
          {require ? (
            <span className="email-required-asterisk">
              <strong>*</strong>
            </span>
          ) : null}
        </Label>
      ) : null}
      <Input
        required={require}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        innerRef={ref}
        className="message-textarea"
        placeholder={placeholder}
        type={type}
        name={name}
        id={id}
      />
      {formText ? <FormText color="muted">{formText}</FormText> : null}
    </FormGroup>
  )
}

export default CustomFormGroup
